const InfoChilderns = [
  { index: 0, route: "https://docs.koindx.com", external: true, label: "Docs" },
  { index: 1, route: "https://koindx.com/terms-of-use", external: true, label: "Terms of Use" },
  { index: 2, route: "https://koindx.com/privacy", external: true, label: "Privacy" },
]

const EarnChildrens = [
  { index: 0, route: "/earn/farms", external: false, label: "Farms" },
  // { index: 1, route: "/earn/pools", external: false, label: "Pools" },
]

export const routes = [
  { index: 0, type: "route", route: "/swap", paths: ["/swap"], label: "Swap" },
  { index: 1, type: "route", route: "/liquidity", paths: ["/liquidity", "/liquidity/add", "/liquidity/remove"], label: "Liquidity" },
  { index: 2, type: "dropdown", route: "earn", paths: ["/earn/farms", "/earn/pools"], childrens: EarnChildrens, label: "Earn" },
  { index: 3, type: "dropdown", route: "info", paths: [], childrens: InfoChilderns, label: "Info" },
];
