import { Provider } from "koilib";
import { get as _get } from "lodash"
import { Request as JSONRequest } from "../helpers/request";

class GlobalController extends JSONRequest {
  constructor() {
    super({ url: import.meta.env.VITE_BACKEND_API_URL });
  }

  async getBlockHeight() {
    return this.getPrepared("/v2/global")
  }

  async getBlockHead() {
    const provider = new Provider([ import.meta.env.VITE_RPC ]);
    return provider.getHeadInfo();
  }
}


export let global = new GlobalController();