import { get as _get } from "lodash";
import React, { useEffect, useState } from "react"; // , {useState}
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { utils as koilibUtils } from "koilib";

// components mui
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppBar, Box, Button, Container, Drawer, IconButton, List, Link, ListItem, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Popper from "@mui/material/Popper";
import Identicon from "react-identicons";
import LogoWhite from "../../assets/images/logo_white.svg";
import { ConnectButton } from "../Buttons/ConnectButton";
// helpers
import shortedAddress from "../../utils/display";
// actions
import { CONFIG_BASE } from "../../constants/configs";
import { routes } from "../../constants/routes";
import { InitializeSwaps, InitializeTokens } from "../../helpers/initialize";
import { setModal, setModalData } from "../../redux/actions/modals";
import { DropdownNav } from "../DropdownNav";
import { setNetwork } from "./../../redux/actions/settings";
import { initializeSwaps as InitializeSwapsRD } from "./../../redux/actions/swap";
import { initializeTokens as InitializeTokensRD } from "./../../redux/actions/tokens";
import { disconnectWallet } from "./../../redux/actions/wallet";

// contracts
import { TokenContract } from "./../../helpers/contracts"

const Header = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // MediaQuery
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  // selectors
  const walletSelector = useSelector((state) => state.wallet);

  const removeTabInPaths = [];
  const [dropdownEl, setDropdownEl] = useState(null);
  const [justOpenedDropdown, setJustOpenedDropdown] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [timer, setTimer] = useState(null);
  const [points, setPoints] = useState("loading...")

  const address = _get(walletSelector, "wallet[0].address", "");
  const connected = _get(walletSelector, "connected", false)

  useEffect(() => {
    const loadPoints = async (wallet) => {
      let provider = _get(walletSelector, "provider", null);
      let signer = _get(walletSelector, "signer", null);
      let contract = TokenContract("19DpgtbBqc5mpENEPiKvMQMvY9UWtmQj5X", provider, signer);
      let address = _get(wallet, "[0].address", "");
      let functions = contract.functions;
      let response = await functions.balanceOf({ owner: address });
      let _points =  koilibUtils.formatUnits(_get(response, "result.value", 0), 8)
      setPoints(_points)
    }

    if(timer) {
      clearInterval(timer);
      setTimer(null);
    }
    let _wallet = _get(walletSelector, "wallet", null);
    if(_wallet) {
      loadPoints(_wallet);
      let t = setInterval(() => loadPoints(_wallet), 10000)
      setTimer(t)
    }
  }, [ _get(walletSelector, "wallet", null) ])

  const disconnect = async () => {
    dispatch(disconnectWallet());
    dispatch(setNetwork(CONFIG_BASE.chain.id));
    let swaps = await InitializeSwaps();
    dispatch(InitializeSwapsRD(swaps));
    let tokens = await InitializeTokens();
    dispatch(InitializeTokensRD(tokens));
    setIsDrawerOpen(false)
  };

  const openModalSettingsApp = () => {
    dispatch(setModalData(null));
    dispatch(setModal("SettingsApp"));
  };
  

  const copyAddress = async () => {
    try {
      await navigator.clipboard.writeText(_get(walletSelector, "wallet[0].address", ""));
    } catch (err) {
      console.log(err)
    }
  };

  const handleDropDown = (event, _route) => {
    setDropdownEl(event.currentTarget);
    setJustOpenedDropdown(_route)
    event.stopPropagation();
  }
  const clickAway = () => {
    if (justOpenedDropdown != null) {
      setDropdownEl(null)
    }
    setJustOpenedDropdown(null)
  }
  const prepareRoutes = () => {
    let routeList = []
    for (let route of routes) {
      if(route.type == "dropdown") {
        routeList.push(
          <Button
            size={"large"}
            sx={{ px: .5, mx: .5, py: "4px", color: "text.main" }}
            key={route.index}
            color={"primary"}
            onClick={(event) => handleDropDown(event, route.route)}
          >
            <Typography textAlign="center" variant="h6" color={(route.paths.indexOf(location.pathname) != -1 || justOpenedDropdown == route.route ? "primary" : "")}>
              {route.label}
            </Typography>
          </Button>
        )
      } else {        
        routeList.push(
          <Button
            size={"large"}
            sx={{ px: .5, mx: .5, py: "4px", color: "text.main" }}
            key={route.index}
            color={"primary"}
            onClick={(event) => navigate(route.route)}
          >
            <Typography textAlign="center" variant="h6" color={(route.paths.indexOf(location.pathname) != -1 ? "primary" : "")}>
              {route.label}
            </Typography>
          </Button>
        )
      }
    }
    return routeList
  }
  const prepareDropdowns = () => {
    let routeList = []
    for (let route of routes.filter(r => r.type == "dropdown")) {
      routeList.push(
        <ClickAwayListener onClickAway={clickAway} key={route.index} disableReactTree={true}>
          <Popper
            id={route.route == justOpenedDropdown ? "header-dropdown-menu" : null}
            open={route.route == justOpenedDropdown}
            anchorEl={dropdownEl}
            popperOptions={{
              placement: "bottom",
            }}
          >
            <DropdownNav dropdownRoutes={route.childrens} dropdownClose={clickAway} />
          </Popper>
        </ClickAwayListener>
      )
    }
    return routeList
  }

  return (
    <AppBar position="static" sx={{ background: "transparent", boxShadow: "none", marginBottom: { xs: "10px", md: "50px" } }}>

      <Container maxWidth="xl" sx={{ px: matches ? 2 : 2 }}>
        <Toolbar sx={{ maxHeight: "52px", justifyContent: matches ? "space-between" : "", px: matches ? 0 : 2 }}>
          <Box style={{ float: "left", cursor: "pointer" }} onClick={() => {
            navigate("/")
          }} >
            <img src={LogoWhite} height={"30px"} alt="KoinDX logo" />
          </Box>
          {!matches ? (
            <Box sx={{ flexGrow: 1, display: { xs: "flex", justifyContent: "flex-end", alignItems: "center" } }}>
              {removeTabInPaths.indexOf(location.pathname) === -1 ? prepareRoutes() : null}
              {removeTabInPaths.indexOf(location.pathname) === -1 ? prepareDropdowns() : null}
            </Box>
          ) : null}
          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
              <IconButton aria-label="settings" sx={{ color: "text.main" }} onClick={() => openModalSettingsApp()}>
                <SettingsIcon />
              </IconButton>
              {connected ? (
                <Button variant="outlined" sx={{ mx: matches ? 0 : 1, textTransform: "none" }} onClick={() => { setIsDrawerOpen(true) }}>
                  <Box sx={{ marginRight: "6px" }}>
                    <Identicon string={address} size={16} palette={[(theme.palette.primary.main)]} />
                  </Box>
                  <Box><Typography variant="body1" color="primary">{shortedAddress(address)}</Typography></Box>
                </Button>
              ) : null}
              {!connected ? (
                <ConnectButton />
              ) : null}
            </Box>
          </Box>
        </Toolbar>


        <Drawer
          anchor={"right"}
          open={isDrawerOpen && connected}
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{
            sx: { width: { xs: "90%", sm: "60%", md: "400px", xl: "350px" } },
          }}
        >
          <Box
            onKeyDown={() => setIsDrawerOpen(!isDrawerOpen)}
            role="presentation">
            <List>
              <ListItem>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                  <Tooltip title="Copy Address">
                    <Button variant="outlined" sx={{ mx: matches ? 0 : 1, textTransform: "none" }} onClick={() => { copyAddress() }}>
                      <Box sx={{ marginRight: "6px" }}>
                        <Identicon string={address} size={16} palette={[(theme.palette.primary.main)]} />
                      </Box>
                      <Box>{shortedAddress(address)}</Box>
                      <ContentCopyIcon sx={{ marginLeft: "6px", fontSize: 12 }} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Disconnect Wallet">
                    <IconButton aria-label="Disconnect" sx={{ color: "text.main" }} onClick={() => disconnect()}>
                      <LogoutIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>

              <ListItem>
                <Box sx={{ width: "100%" }}>

                  <Box sx={{ display: "flex", justifyContent: "space-between", padding: "8px", borderRadius: "10px", backgroundColor: "background.light" }}>
                    <Typography>KOINDX POINTS:</Typography>
                    <Typography>{ points }</Typography>
                  </Box>

                </Box>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Container >
    </AppBar >
  );
};

export default Header;
