import { CHAIN_NAMES } from "./chains"

const _farms = {
  [CHAIN_NAMES.MAINNET]: [
    {
      address: "12X3zJ71EwmCsYhnbvsrUUkTB9LjWkvPUL", // KOIN-USDT
      tokenA: "koin",
      tokenB: "14MjxccMUZrtBPXnNkuAC5MLtPev2Zsk3N",
      metadata: {
        name: "KOIN-USDT POINTS",
        community: false
      },
    },
    {
      address: "1Cg38hAHSFK9DynwMsbHUmP7EzeXSxy86o", // KOIN-ETH
      tokenA: "koin",
      tokenB: "15twURbNdh6S7GVXhqVs6MoZAhCfDSdoyd",
      metadata: {
        name: "KOIN-ETH POINTS",
        community: false
      },
    },
    {
      address: "1Ht9gnbiRLPsAJj7atQUC67LJzBL3cEuuR", // KOIN-BTC
      tokenA: "koin",
      tokenB: "15zQzktjXHPRstPYB9dqs6jUuCUCVvMGB9",
      metadata: {
        name: "KOIN-BTC POINTS",
        community: false
      },
    },
    {
      address: "1FVmM3DoNeFvif4qFVBHm6BqMNMiDSigMu", // KOIN-VHP
      tokenA: "koin",
      tokenB: "vhp",
      metadata: {
        name: "KOIN-VHP POINTS",
        community: false
      },
    }
  ],
  [CHAIN_NAMES.TESTNET]: [
  ]
}

const _pools = {
  [CHAIN_NAMES.MAINNET]: [
  ],
  [CHAIN_NAMES.TESTNET]: [
    // {
    //   address: "18D2BcRH3fiKLvtkXrgJsyYeYiJnKZTt4k",
    //   metadata: {
    //     community: false
    //   },
    // }
  ]
}

// exports
const APP_CHAIN = import.meta.env.VITE_CHAIN;
export const farms = _farms[APP_CHAIN];
export const pools = _pools[APP_CHAIN];
