import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { utils as koilibUtils } from "koilib"
import { get as _get } from "lodash";
import { BigNumber } from "bignumber.js";

// constants
import { farms as ListFarms } from "./../../constants/earn"

// icons
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// components
import { useTheme, Box, Paper, Container, Card, CardContent, CardHeader, CircularProgress, Typography, Chip, Modal, IconButton, Tooltip } from "@mui/material";
import FiltersEarn from "../../components/FiltersEarn";
import StakingComponent from "../../components/Staking";

// api
import { pair } from "./../../services/PairController"

// contracts
import { StakingContract } from "../../helpers/contracts";


const FarmsPage = () => {
  // Hooks
  const theme = useTheme();

  // components state
  const [stake, setStake] = useState(null)
  const [timer, setTimer] = useState(null)
  const [farms, setFarms] = useState([])
  const [loading, setLoading] = useState(true)

  // filters state
  const [filterStake, setFilterStake] = useState(false);
  const [filterStatus, setFilterStatus] = useState("Live");
  const [filterSearch, setFilterSearch] = useState(null);

  // selectors
  const headInfo = useSelector((state) => state.blockchain.headInfo);
  const walletSelector = useSelector(state => state.wallet);
  const base_tokens = useSelector((state) => state.tokens.base_tokens);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);

  // variables
  const provider = _get(walletSelector, "provider", null)
  const signer = _get(walletSelector, "signer", null)


  // load farms
  let loadPool = async () => {
    let result = []
    for (let index = 0; index < ListFarms.length; index++) {
      let _farm = ListFarms[index];

      // data
      let pool = null;

      // load farm
      let _contractStaking = StakingContract(_farm.address, provider, signer);
      try {
        pool = (await _contractStaking.functions.get_pool({})).result
      } catch (error) {
        console.log(error)
        continue;
      }

      // tokens data
      let tokenList = base_tokens.concat(custom_tokens);
      let tokenA = tokenList.find(token => token.address === _get(_farm, "tokenA", ""));
      let tokenB = tokenList.find(token => token.address === _get(_farm, "tokenB", ""));
      let tokenReward = tokenList.find(token => token.address === _get(pool, "tokenReward", ""));
      result.push({
        ...pool,
        ..._farm,
        tokenA,
        tokenB,
        tokenReward,
        totalEarn: "0",
        balance: "0",
        totalLiquidity: "0",
      })
    }
    return result;
  }

  // load balance
  let loadBalances = async (farmsbase = []) => {
    let result = [];
    for (let index = 0; index < farmsbase.length; index++) {
      let _farm = farmsbase[index];

      // data
      let pool = null;
      let balance = 0;

      // load farm
      let _contractStaking = StakingContract(_farm.address, provider, signer);
      if(_get(walletSelector, "wallet", null)) {
        try {
          balance = (await _contractStaking.functions.balance_of({ owner: signer.getAddress() })).result;
        } catch (error) {
          console.log(error)
          continue;
        }
      }

      // load pool info from api
      let lpId = _get(_farm, "tokenDeposit", "")
      if(lpId) {
        try {
          pool = await pair.getPairById(lpId)
        } catch (error) {
          console.log(error)
        }
      }
      
      result.push({
        ..._farm,
        pool: pool ? pool.result : null,
        balance: balance ? balance.value : "0",
      })
    }
    return result;
  }

  // load earn in farms
  let loadEarns = async (farmsbase = []) => {
    let result = [];
    for (let index = 0; index < farmsbase.length; index++) {
      let _farm = farmsbase[index];
      let totalEarn;
      let _contractStaking = StakingContract(_farm.address, provider, signer);
      if(_get(walletSelector, "wallet", null)) {
        try {
          totalEarn = (await _contractStaking.functions.get_pending_reward({ owner: signer.getAddress() })).result;
        } catch (error) {
          console.log(error)
          continue;
        }
      }
      result.push({
        ..._farm,
        totalEarn: totalEarn ? totalEarn.value : "0"
      })
    }
    return result;
  }
  
  // load farms
  let loadFarms = async () => {
    try {
      let result = []
      result = await loadPool();
      result = await loadBalances(result)
      result = await loadEarns(result);
      setFarms(result);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const applyFilters = (_farms) => {
    let result = _farms

    // filter stake
    if(filterStake) {
      result = result.filter(farm => farm.balance != "0")
    }

    // filter status
    if(filterStatus == "Finished") {
      let blockHead = new BigNumber(_get(headInfo, "head_topology.height", "0"))
      result = result.filter(farm => {
        let blockEnd = new BigNumber(_get(farm, "blockEnd", "0"))
        if(blockHead.isGreaterThan(blockEnd)) {
          return farm;
        }
      })
    }


    if(filterSearch) {

    }


    return result
  }

  // effects
  useEffect(() => {
    if(base_tokens.concat(custom_tokens).length) {
      // only load when loading is false
      loadFarms()
    }
  }, [ base_tokens.concat(custom_tokens).length ])

  useEffect(() => {
    let _timer = timer;
    const updateValues = async () => {
      let result = farms
      result = await loadBalances(result)
      result = await loadEarns(result);
      setFarms(result);
      if(_timer) {
        clearTimeout(_timer)
        _timer = null;
      }
      _timer = setTimeout(updateValues, 8000)
      setTimer(_timer)
    }
    if(farms.length) {
      updateValues();
    }
    return () => {
      if(_timer) {
        clearTimeout(_timer)
        _timer = null;
      }
    }
  }, [ loading, _get(walletSelector, "wallet", null) ])

  if(loading) {
    return (
      <Card sx={{ padding: "1em", maxWidth: "400px", marginX: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <CardHeader
          title={"Farms"}
          sx={{ paddingBottom: "4px", textTransform: "uppercase" }}
        />
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress sx={{ marginBottom: "40px" }} />
        </Box>
        <Typography>We are loading the farms...</Typography>
      </Card>
    )
  }

  const getImageToken = (logoURI)=> {
    if(logoURI != "") {
      return (
        <img src={logoURI} style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="token" />
      )
    }
    return <HelpRoundedIcon color="primary" style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="token" />
  }

  const getStatus = (el) => {
    let blockHead = new BigNumber(_get(headInfo, "head_topology.height", "0"))
    let blockStart = new BigNumber(_get(el, "blockStart", "0"))
    let blockEnd = new BigNumber(_get(el, "blockEnd", "0"))
    if(blockHead.isLessThan(blockStart)) {
      return <Chip label="Waiting" color="info" />
    }
    if(blockHead.isGreaterThan(blockEnd)) {
      return <Chip label="Finished" color="error" />
    }
    return <Chip label="Live" color="primary" />
  }

  // console.log(farms)
  const DetailsFarm = ({ el }) => {

    let supply = koilibUtils.formatUnits(_get(el, "supplyDeposit", "0"), 8)
    let valueUSD = new BigNumber(supply).times(_get(el, "pool.reserveUSD", 0));
    let valorUSDStaked = valueUSD.div(_get(el, "pool.totalSupply", "0"))
    let blockRewards = koilibUtils.formatUnits(_get(el, "blockReward", "0"), 8)
    let pricePerLP = valorUSDStaked.div(_get(el, "pool.reserveUSD", 0));
    let dailyRewards = new BigNumber(new BigNumber(blockRewards).div(supply)).times(28800);
    let APR = new BigNumber(100).times( ((dailyRewards.div(supply)).times(28800)).div( (valorUSDStaked.div(supply)).times(pricePerLP)) )

    // if(el.address == "1Ht9gnbiRLPsAJj7atQUC67LJzBL3cEuuR") {
    //   console.log(el)
    //   console.log(_get(el, "pool.reserveUSD", 0))
    //   console.log("supply:", supply)
    //   console.log("pricePerLP:", pricePerLP.toString())
    //   console.log("valorUSDStaked:", valorUSDStaked.toString())
    //   console.log("blockRewards:", blockRewards)
    //   console.log("dailyRewards:", dailyRewards.toString())
    // }

    // console.log(APR.toFixed(0))

    return (
      <Box onClick={() => setStake(el)} sx={{ mt: ".75em", width: "100%", height: "auto", backgroundColor: "background.light", }} borderRadius={"10px"}>
        <Box sx={{ padding: { xs: "10px", sm: "10px 10px 10px 10px" }, display: "flex", justifyContent: "space-around", "&:hover": { cursor: "pointer" }, height: "80px", maxHeight: "100px" }}>

          <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: { xs: "100%", sm: "60%", md: "42%" } }}>
            <Box sx={{ position: "relative", width: "50px", height: "44px" }}>
              <Box sx={{ position: "absolute", width: "50%", inset: "0px auto auto 0px" }}>
                { getImageToken(_get(el, "tokenB.logoURI", "")) }
              </Box>
              <Box sx={{ position: "absolute", width: "82%", inset: "auto 0px 0px auto" }}>
                { getImageToken(_get(el, "tokenA.logoURI", "")) }
              </Box>
            </Box>
            <Typography>{ _get(el, "metadata.name", "") != "" ? _get(el, "metadata.name", "") : `${_get(el, "tokenA.symbol", "")}-${ _get(el, "tokenB.symbol", "")}`  }</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "inline-flex", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "40%", md: "10%" } }}>
            { getStatus(el) }
          </Box>
          {/* <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "40%", md: "12%" } }}>
            <Typography>
              ~{ APR.toString().substring(0, 10) }
            </Typography>
          </Box> */}
          <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "0%", md: "16%" } }}>
            <Typography>
            {
              _get(el, "totalEarn", "0") != "0" ? `${koilibUtils.formatUnits(_get(el, "totalEarn", "0"), 8)}` : "0"
            }
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "0%", md: "16%" } }}>
            <Typography>{koilibUtils.formatUnits(_get(el, "balance", "0"), 8)} LP</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "0%", md: "16%" } }}>
            <Typography>{koilibUtils.formatUnits(_get(el, "supplyDeposit", "0"), 8)} LP</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Container sx={{ maxWidth: "2140px" }}>
      <Box component={Paper} sx={{ padding: "20px" }}>
        <FiltersEarn
          name="Farms"
          search="Search farm..."
          enableStake={true} filterStake={filterStake} setFilterStake={setFilterStake}
          enableStatus={true} filterStatus={filterStatus} setFilterStatus={setFilterStatus}
          enableSearch={false} filterSearch={filterSearch} setFilterSearch={setFilterSearch}
        />


        <Box>
          <Box sx={{ mt: "2em", display: "flex", padding: { xs: "0px", sm: "10px 10px 10px 10px" } }}>
            <Box display={"inline-flex"} sx={{ width: { xs: "100%", sm: "60%", md: "42%" } }}>
              <Typography>Farm Name</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "inline-flex", md: "inline-flex" }, width: { xs: "100%", sm: "15%", md: "10%" } }}>
              <Typography>Active</Typography>
            </Box>
            {/* <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "14%" } }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>Rewards</Typography>
                <Tooltip sx={{ mx: 1 }} title="Daily reward for every $100 in stake">
                  <InfoOutlinedIcon color="primary" />
                </Tooltip>
              </Box>
            </Box> */}
            <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "16%" } }}>
              <Typography>Earn</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "16%" } }}>
              <Typography>Staked</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "16%" } }}>
              <Typography>Total Staked</Typography>
            </Box>
          </Box>

          {
            farms.length != 0 ?
              applyFilters(farms).map((el, i) => <DetailsFarm el={el} key={i} />)
            : (
              <Card variant="outlined" sx={{ marginTop: "10px" }}>
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                  <Typography variant="subtitle1" textAlign={"center"}>There are no active farms</Typography>
                </CardContent>
              </Card>
            )
          }

          <Modal
            open={!!stake}
            onClose={() => setStake(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex" }}
          >
            <Box
              sx={{
                padding: "10px",
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                borderRadius: "10px",
                margin: "auto auto"
              }}
            >
              <StakingComponent
                type={"farm"}
                name={ `${_get(stake, "tokenA.symbol", "") }-${ _get(stake, "tokenB.symbol", "")}` }
                image={
                  <Box sx={{ position: "relative", width: "50px", height: "44px" }}>
                    <Box sx={{ position: "absolute", width: "50%", inset: "0px auto auto 0px" }}>
                      { getImageToken(_get(stake, "tokenB.logoURI", "")) }
                    </Box>
                    <Box sx={{ position: "absolute", width: "82%", inset: "auto 0px 0px auto" }}>
                      { getImageToken(_get(stake, "tokenA.logoURI", "")) }
                    </Box>
                  </Box>
                }
                onClose={() => setStake(null)}
                {...stake}
              />
            </Box>
          </Modal>

        </Box>
      </Box>
    </Container>
  )
}

export default FarmsPage;