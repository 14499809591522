import React, { useEffect } from "react";
import { get as _get } from "lodash";
import { useDispatch } from "react-redux";
import AppRouter from "./AppRouter";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import { global } from "./services/GlobalController";

// reducers
import { setHeadInfo } from "./redux/actions/blockchain";
import { setBlockHeight } from "./redux/actions/dexdata";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const load = async () => {
      let info = await global.getBlockHeight()      
      dispatch(setBlockHeight(_get(info, 'head_block', "0")));

      let head = await global.getBlockHead();
      dispatch(setHeadInfo(head));
    }
    load();
  }, [])
  return (
    <AppRouter />
  )
};

export default App;
