import { get as _get } from "lodash"
import { Request as JSONRequest } from "../helpers/request";

class PairController extends JSONRequest {
  constructor() {
    super({ url: import.meta.env.VITE_BACKEND_API_URL });
  }

  async getPairById(pairId) {
    return await this.getPrepared(`/v2/pairs/byId`, { id: pairId });
  }
  
  async getPairsByIdWithToken(pairIds) {
    return await this.getPrepared(`/v2/pairs/byTokens`, { ids: pairIds.join(",") });
  }
}


export let pair = new PairController();
