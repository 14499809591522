/* eslint-disable */
import React, { useState, useEffect } from "react";
import { BigNumber } from "bignumber.js"
import { useSelector, useDispatch } from "react-redux";
import { get as _get } from "lodash";
import { utils as koilibUtils } from "koilib";
import { numberPattern } from "../../utils/regex"
import { useSnackbar } from "notistack";

// Mui components
import { Card, CardContent, Button, Box, Typography, InputBase, IconButton, CircularProgress } from "@mui/material";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import CloseIcon from "@mui/icons-material/Close";

// Helpers
import { calculateDefaultMana } from "../../helpers/calculate"

// Utils
import { waitTransation } from "../../utils/transactions"
import { getAddressToken } from "../../utils/token"

// Actions
import { setModal } from "../../redux/actions/modals";

// Contracts
import { TokenContract, StakingContract } from "../../helpers/contracts"


const Staking = (props) => {
  const dispatch = useDispatch();
  const Snackbar = useSnackbar();

  // states
  const [balance, setBalance] = useState("0");
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [inputForm, setInputForm] = useState(null);
  const [inputValue, setInputValue] = useState("0");

  // selectors
  const walletSelector = useSelector(state => state.wallet);

  // variables
  const provider = _get(walletSelector, "provider", null)
  const signer = _get(walletSelector, "signer", null)


  const approvalAllowance = async (tokenLPAddress, ownerAddress, spenderAddress, amount, configs) => {
    let _token = TokenContract(tokenLPAddress, provider, signer);
    _token.options.onlyOperation = true;
    const approvalParams = {
      owner: ownerAddress,
      spender: spenderAddress,
      value: amount
    }
    let { operation: approveSales } = await _token.functions.approve(approvalParams, configs)
    return approveSales;
  }

  const getConfigs = async (_walletAddress) => {
    let configs = {
      payer: _walletAddress,
    };
    try {
      let account_rc = await provider.getAccountRc(_walletAddress);
      let rc_limit = calculateDefaultMana(account_rc);
      configs.rc_limit = rc_limit;
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Error in mana calculation", { variant: "error" });
      return;
    }
    return configs;
  }

  const finalStaking = async () => {
    setLoading(true)

    // config contract
    let walletAddress = signer.getAddress();
    let amountBal = koilibUtils.parseUnits(inputValue, 8)
    let configs = await getConfigs(walletAddress);
    let operations = [];

    // approve allowance of lp token
    try {
      let _address = props.tokenDeposit
      let allowance = true;
      if(props.type != "farm") {
        addressDeposit = await getAddressToken(_get(props, "tokenDeposit.address", ""));
        if(_get(props, "tokenDeposit.allowance") == false) {
          allowance = false;
        }
      }
      if(allowance) {
        let approvalTokenA = await approvalAllowance(_address, walletAddress, props.address, amountBal, configs);
        if (approvalTokenA) {
          operations.push(approvalTokenA)
        }
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Approval token a error", { variant: "error" });
      return;
    }


    // staking and unstaking
    let _contractToken = StakingContract(props.address, provider, signer);
    _contractToken.options.onlyOperation = true;
    try {
      let _params = {
        from: walletAddress,
        value: amountBal
      };
      let result
      if(inputForm == "STAKE") {
        result = await _contractToken.functions.deposit(_params, configs)
      } else {
        result = await _contractToken.functions.withdraw(_params, configs)
      }
      operations.push(result.operation)
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar(`Error in ${inputForm}`, { variant: "error" });
      return;
    }


    let transaction = null;
    let receipt = null;
    try {
      const tx = await signer.prepareTransaction({ operations: operations, header: configs });
      const { transaction: _transaction, receipt: _receipt } = await signer.sendTransaction(tx);
      transaction = _transaction
      receipt = _receipt
      Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
      setLoading(false);
      return;
    }
    // wait to be mined
    await waitTransation(provider, transaction)
    clear();
    Snackbar.enqueueSnackbar(inputForm, { variant: "success" });
  }

  const clear = () => {
    setBalance(0);
    setLoading(false);
    setLoadingBalance(false);
    setInputForm(null);
    setInputValue(null);
  }

  const selectForm = async (_form) => {
    setLoadingBalance(true)
    let _balance = "0";
    if(_form == "STAKE") {

      let addressDeposit = props.tokenDeposit
      if(props.type != "farm") {
        addressDeposit = await getAddressToken(_get(props, "tokenDeposit.address", ""));
      }
      let _contractToken = TokenContract(addressDeposit, provider, signer);
      try {
        let result = (await _contractToken.functions.balanceOf({ owner: signer.getAddress() })).result;
        _balance = koilibUtils.formatUnits(_get(result, "value", "0"), 8);
      } catch (error) {
        console.log(error)
      }
    } else {
      _balance = koilibUtils.formatUnits(props.balance, 8)
    }
    setBalance(_balance);
    setInputForm(_form)
    setLoadingBalance(false);
  }

  const havest = async () => {
    setLoading(true);

    // config contract
    let walletAddress = signer.getAddress();
    let configs = await getConfigs(walletAddress);
    let operations = [];

    // havest
    let _contractToken = StakingContract(props.address, provider, signer);
    _contractToken.options.onlyOperation = true;
    console.log(_contractToken)
    try {
      let _params = {
        from: walletAddress,
      };
      let result = await _contractToken.functions.claim_pending_reward(_params, configs)
      operations.push(result.operation)
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error in havest", { variant: "error" });
      return;
    }

    // transaction
    let transaction = null;
    let receipt = null;
    try {
      const tx = await signer.prepareTransaction({ operations: operations, header: configs });
      const { transaction: _transaction, receipt: _receipt } = await signer.sendTransaction(tx);
      transaction = _transaction
      receipt = _receipt
      Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
      setLoading(false);
      return;
    }
    // wait to be mined
    await waitTransation(provider, transaction)
    setLoading(false);
    Snackbar.enqueueSnackbar("Havest", { variant: "success" });
  }

  if(inputForm) {
    return (
      <Card variant="outlined" sx={{ marginX: "auto", borderRadius: "10px", backgroundColor: "background.default", padding: "20px" }}>
        <CardContent sx={{ padding: { xs: "5px", sm: "10px" } }}>
          <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", marginBottom: 2 }}>
            <IconButton aria-label="back" onClick={clear}>
              <ArrowBackIcon color="secondary" />
            </IconButton>
            <Typography>{ inputForm } { props.name }</Typography>
            {
              props.onClose ?
                <IconButton component="span" onClick={props.onClose}>
                  <CloseIcon color="secondary" />
                </IconButton>
              : null
            }
          </Box>
          <Box sx={{ backgroundColor: "background.light", borderRadius: "10px", marginTOp: "1rem", padding: "10px 15px", marginBottom: 2 }}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <InputBase
                placeholder="0"
                type="text"
                value={inputValue}
                sx={{ fontSize: "1.25em !important", color: "text.main", ml: 1, flex: 1, height: "40px" }}
                onChange={(e) => numberPattern(e.currentTarget.value, setInputValue)}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
              <Box>
                {
                  _get(walletSelector, "wallet", null) ? <Typography variant="body1" component={"span"} sx={{ color: "text.grey2", marginTop: "5px" }}>Balance: {loadingBalance ? "loading..." : balance}</Typography> : null
                }
              </Box>
              <Box sx={{ display: "flex", justifyContent: "right", padding: "0" }}>
                <div style={{ marginTop: "8px", marginRight: "0" }}>
                  <Button
                    variant="dark-grey-contained"
                    size="small"
                    sx={{ marginRight: "5px", paddingX: "0px" }}
                    onClick={() => {
                      console.log("MAX")
                      numberPattern(balance, setInputValue);
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "text.grey2" }}>
                      MAX
                    </Typography>
                  </Button>
                  <Button
                    variant="dark-grey-contained"
                    sx={{ padding: "10px", display: { xs: "none", sm: "inline-flex" } }}
                    size="small"                    
                    onClick={() => {
                      console.log("HALF")
                      numberPattern(balance, (b) => {
                        let fullAmount = koilibUtils.parseUnits(b, 8);
                        let _amount = new BigNumber(fullAmount).dividedBy(2).toFixed(0, 1);
                        let value = koilibUtils.formatUnits(_amount, 8);
                        setInputValue(value);
                      });
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "text.grey2" }}>
                      HALF
                    </Typography>
                  </Button>
                </div>
              </Box>            
            </Box>
          </Box>
          <Button
            variant="contained"
            disabled={loading || !inputValue || new BigNumber(inputValue).isZero() || !balance || new BigNumber(balance).isZero()}
            size="small"
            sx={{ width: "100%", borderRadius: "10px", marginBottom: inputForm == "STAKE" ? 2 : 0 }}
            onClick={() => finalStaking()}
          >
            { loading ? <CircularProgress color="background" size={20} sx={{ marginRight: "10px" }} /> : null }
            CONFIRM
          </Button>
          {/* {
            inputForm == "STAKE" ?
            <Button
              variant="outlined"
              disabled={false}
              size="small"
              sx={{ width: "100%", borderRadius: "10px" }}
              onClick={() => console.log("get lp")}
            >
              GET { props.name }
            </Button>
            : null
          } */}
        </CardContent>
      </Card>
    )
  }
  
  return (
    <Card variant="outlined" sx={{ marginX: "auto", borderRadius: "10px", backgroundColor: "background.default", padding: "20px" }}>
      <CardContent sx={{ padding: { xs: "5px", sm: "10px" } }}>
        <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center", marginBottom: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              { props.image }
            </Box>
            <Typography sx={{ marginLeft: 1 }}>{ props.name }</Typography>
          </Box>
          {
            props.onClose ?
              <IconButton component="span" onClick={props.onClose}>
                <CloseIcon color="secondary" />
              </IconButton>
            : null
          }
        </Box>
        <Box>

          {
            !_get(walletSelector, "wallet", null)
              ? (
                <Button variant="contained" sx={{ width: "100%" }} onClick={() => dispatch(setModal("Connect"))}> Connect Wallet </Button>
              ) : (
                <Box variant="outlined" sx={{ width: "100%", borderRadius: "10px" }}>

                  <Box sx={{ justifyContent: "space-between", display: "flex", marginBottom: 2 }}>
                    <Typography noWrap>Staked</Typography>
                    <Typography noWrap>{koilibUtils.formatUnits(_get(props, "balance", "0"), 8)} { props.type == "farm" ? "LP" : _get(props, "tokenDeposit.symbol", "") }</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", marginBottom: 2 }}>
                    <Typography noWrap>Total Staked</Typography>
                    <Typography noWrap>{koilibUtils.formatUnits(_get(props, "totalLiquidity", "0"), 8)} { props.type == "farm" ? "LP" : _get(props, "tokenDeposit.symbol", "") }</Typography>
                  </Box>
                  <Box sx={{ width: "100%", justifyContent: "space-between", display: "flex", marginBottom: 2 }}>
                    <Button
                      variant="outlined"
                      disabled={true}
                      sx={{ width: "48%", borderRadius: "10px" }}
                    >
                      {
                        _get(props, "totalEarn", "0") != "0" ?
                          `${koilibUtils.formatUnits(_get(props, "totalEarn", "0"), _get(props, "tokenReward.decimals", 8))} ${_get(props, "tokenReward.symbol", "")}`
                        : "0"
                      }
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={loading || new BigNumber(_get(props, "totalEarn", "0")).isZero()}
                      sx={{ width: "48%", borderRadius: "10px" }}
                      onClick={() => havest()}
                    >
                      { loading ? <CircularProgress color="background" size={20} sx={{ marginRight: "10px" }} /> : null }
                      HARVEST
                    </Button>
                  </Box>

                  <Button
                    variant="contained"
                    disabled={loading}
                    size="small"
                    sx={{ width: "100%", borderRadius: "10px", marginBottom: 2 }}
                    onClick={() => selectForm("STAKE")}
                  >
                    STAKE { props.name  }
                  </Button>
                  <Button
                    variant="contained"
                    disabled={loading}
                    size="small"
                    sx={{ width: "100%", borderRadius: "10px" }}
                    onClick={() => selectForm("UNSTAKE")}
                  >
                    UNSTAKE { props.name  }
                  </Button>
                </Box>
              )
          }
        </Box>
      </CardContent>
    </Card>
  )
}

export default Staking; 